import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => (
  <footer className="container flex justify-center mx-auto pt-4 px-3 mt-20 mb-8 text-gray-800">
    <div className="flex flex-col mx-3 items-center justify-center">
      <StaticImage src="../../images/logo.jpg" alt="logo" width="250" />

      <div className="m-3">
        <a 
          target="_blank" 
          rel="noreferrer"
          href="https://www.bbb.org/ca/ns/north-kentville/profile/janitor-service/wilson-farr-cleaning-solutions-0087-88469?utm_campaign=bbb_seal&utm_content=Wilson%20%26%20Farr%20Cleaning%20Solutions&utm_medium=website&utm_source=seal_click_88469">
            <StaticImage alt="Wilson & Farr Cleaning Solutions BBB accredited business profile" 
            src="../../images/ab-seal-horizontal-can-black.png"
            style={{width: '10rem' }} 
            />
            <div className="text-center" >
             <span style= {{ color: '#dddddd', fontSize: '0.7rem', fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                BBB RATING: A+
              </span>
            </div>
          </a>
      </div>
      <div className="flex text-xl">
        <a
          href="https://www.facebook.com/Wilson-Farr-Cleaning-Solutions-113754693875893"
          target="_blank"
          rel="noreferrer"
          className="p-6"
        >
          <FontAwesomeIcon icon={faFacebookF} style={{ height: `27px` }} />
        </a>
        <a
          href="https://www.instagram.com/wilsonandfarrcleaning/"
          target="_blank"
          rel="noreferrer"
          className="p-6"
        >
          <FontAwesomeIcon icon={faInstagram} style={{ height: `30px` }} />
        </a>
        <a
          href="https://www.youtube.com/@wilsonandfarrcleaning"
          target="_blank"
          rel="noreferrer"
          className="p-6"
        >
          <FontAwesomeIcon icon={faYoutube} style={{ height: `30px` }} />
        </a>
        <a
          href="https://www.tiktok.com/@wilsonandfarrcleaning"
          target="_blank"
          rel="noreferrer"
          className="p-6"
        >
          <FontAwesomeIcon icon={faTiktok} style={{ height: `30px` }} />
        </a>
      </div>
      <p className="p-2 font-thin text-lg">Kentville, Nova Scotia</p>
      <p className="p-2 text-lg font-sans">
        <a href="tel:+19029930049" className="text-white">
          902-993-0049
        </a>
      </p>
      <p className="p-2 font-medium md:text-lg ">
        <a href="mailto:info@wilsonfarrcleaningsolutions.com">
          info@wilsonfarrcleaningsolutions.com
        </a>
      </p>
      <p className="pt-16 text-xs font-light">
        © {new Date().getFullYear()} Wilson & Farr Cleaning Solutions
      </p>
      <p className="pt-2 text-sm font-mono">
        site by{" "}
        <a href="https://acbconsulting.tech" target="_blank" rel="noreferrer">
          ACB Consulting
        </a>
      </p>
    </div>
  </footer>
);

export default Footer;
